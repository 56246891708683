import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_de from "./translations/de/common.json";
import common_pl from "./translations/pl/common.json";
import * as serviceWorker from './serviceWorker';

//let params = (new URL(document.location)).searchParams;
//let lang = params.get('lang');
let tld = window.location.origin.split('.').pop();
if (tld === 'com') {
  tld = 'en'
}

// TRANSLATIONS
i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: tld,
  resources: {
    en: {
      common: common_en
    },
    fr: {
      common: common_fr
    },
    pl: {
      common: common_pl
    },
    es: {
      common: common_es
    },
    de: {
      common: common_de
    },
  },
});



ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App lang={tld} />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
