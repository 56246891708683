import React, { Component } from "react";
import { Form, Table, Button } from "react-bootstrap";
import axios from 'axios';
import { withTranslation } from 'react-i18next';

const FEE = 21;
class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryListFrom: [],
      countryListTo: [],
      rate: '',
      rateLink: '',
      currencyFrom: 'GBP',
      currencyTo: 'EUR',
      rateMessage: this.props.t('selectcurrencies'),
      isLoaded: true,
      bankRate: '',
      bankAmount: '',
      xpAmount: '',
      youSave: '',
      youSaveAmount: '',
      senderAmount: '1000',
      receiverAmount: '',
      platformLink: 'https://secure.xendpay.com/web/registration-step1'

    };


  }

  componentDidMount() {

    // GEOLOCATION
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      this.setState({ currencyFrom: data.currency })
    }).catch((error) => {
      console.log(error);
    });


    //if (this.props.lang === 'en') {
    //  this.setState({ platformLink: 'https://register.xendpay.com/web/reg/register?brand=XP&lang=en' })
    //} else {
    //  this.setState({ platformLink: 'https://secure.xendpay.com/web/registration-step1' })
    //}

    axios.get(`https://secure.xendpay.com/currencies/from?cdc=true`)
      .then(res => {
        this.setState({ countryListFrom: res.data });
      })
    axios.get(`https://secure.xendpay.com/currencies/to?cdc=true`)
      .then(res => {
        this.setState({ countryListTo: res.data });
      })

    axios.get(`https://secure.xendpay.com/rate/XP/${this.state.currencyFrom}/${this.state.currencyTo}?cdc=true`)
      .then(res => {
        this.setState({ rate: res.data })
        this.setState({ receiverAmount: (this.state.senderAmount * this.state.rate).toFixed(2) })
        const averageBankRate = this.state.rate * 0.965;
        this.setState({ bankRate: averageBankRate })
        this.setState({ youSaveAmount: this.state.receiverAmount - this.state.bankAmount })
        const bankAmount = (this.state.senderAmount * this.state.bankRate).toFixed(2);
        this.setState({ bankAmount: bankAmount })
        this.setState({ youSave: this.state.receiverAmount - this.state.bankAmount })

      })

  } // componentDidMount

  render() {
    const { t, i18n } = this.props;

    const fromCurrencyHandler = (e) => {
      this.setState({ currencyFrom: e.target.value })
      getRate(e.target.value, this.state.currencyTo, 'from');


    }

    const toCurrencyHandler = (e) => {
      this.setState({ currencyTo: e.target.value })
      getRate(this.state.currencyFrom, e.target.value, 'to');

    }


    const getRate = (base, symbol, direction) => {

      if (base === symbol) {
        this.setState({ rateMessage: t('selectcurrencies') })
      } else {

        axios.get(`https://uat.xendpay.com/rate/XP/${base}/${symbol}?cdc=true`)
          .then(res => {
            this.setState({ rateLink: `https://uat.xendpay.com/rate/XP/${base}/${symbol}` })
            this.setState({ rate: res.data });
            this.setState({ rateMessage: `1 ${base} = ${this.state.rate} ${symbol}` })
            const averageBankRate = this.state.rate * 0.965;
            this.setState({ bankRate: averageBankRate })


            if (direction === 'to') {
              const receiverAmount = parseInt(this.state.senderAmount * this.state.rate).toFixed(2)
              this.setState({
                receiverAmount: receiverAmount,

              })
            } else {
              const sendAmount = parseFloat(this.state.receiverAmount / this.state.rate).toFixed(2);
              this.setState({
                senderAmount: sendAmount,

              })
            }
            const bankAmount = parseInt(this.state.senderAmount * averageBankRate).toFixed(2)
            const xpAmount = parseInt(this.state.senderAmount * this.state.rate).toFixed(2)
            this.setState({
              bankAmount: bankAmount,
              xpAmount: xpAmount,
              youSave: xpAmount - bankAmount
            })


            //bankAmount()

          })
          .catch((res) => {
            this.setState({ rateLink: `https://uat.xendpay.com/rate/XP/${base}/${symbol}?cdc=true` })
            this.setState({ rateMessage: 'We do not offer this rate' })
            this.setState({ rate: '0.00' })
          })
      }

    }

    const fromAmountHandler = (e) => {
      this.setState({
        senderAmount: e.target.value
      });
      getRate(this.state.currencyFrom, this.state.currencyTo, 'to');
    }

    const toAmountHandler = (e) => {
      this.setState({
        receiverAmount: e.target.value
      });
      getRate(this.state.currencyFrom, this.state.currencyTo, 'from');
    }

    return (

      <div className="Quote">
        <Form className='mb-3'>
          <Form.Group className='mb-0' controlId="QuoteForm_Send">
            <Form.Label className='text-left d-block mb-0'>{t('yousend')}</Form.Label>
            <div className='send-currency d-flex'>
              <Form.Control type="number" step='0.01' placeholder='1000.00' value={this.state.senderAmount} onChange={fromAmountHandler} />
              <Form.Group controlId="sendFrom">
                <Form.Control as="select" value={this.state.currencyFrom} onChange={fromCurrencyHandler}>
                  {this.state.countryListFrom.map(country => (
                    <option className={country.code} value={country.code} key={country.code}>{country.code}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>

          </Form.Group>
          <Form.Group className='mb-0' controlId="QuoteForm_Receive">
            <Form.Label className='text-left d-block mb-0'>{t('yourecieve')}</Form.Label>
            <div className='recieve-currency d-flex'>
              <Form.Control type="number" step='0.01' placeholder='1000.00' value={this.state.receiverAmount} onChange={toAmountHandler} />
              <Form.Group controlId="sendTo">
                <Form.Control as="select" value={this.state.currencyTo} onChange={toCurrencyHandler}>
                  {this.state.countryListTo.map(country => (
                    <option className={country.code} value={country.code} key={country.code}>{country.code}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </Form.Group>
        </Form>
        <div className="Quote__notification d-flex justify-content-between mb-0">

          {this.state.rate > 0 && this.state.currencyFrom !== this.state.currencyTo ? `${t('yousave')} ${this.state.currencyTo} ${parseFloat(this.state.youSave + FEE).toFixed(2)} ${t('yourbank')}` : <>{this.state.rateMessage}</>}


        </div>
        { this.state.rate > 0 && this.state.currencyFrom !== this.state.currencyTo ?
          <Table bordered className="comparison-table">
            <thead>
              <tr>
                <th></th>
                <th className="text-left">Xendpay</th>
                <th className="text-left">{t('avgbank')}</th>
                <th className="text-left">{t('yousave')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>{t('rate')}</strong></td>
                <td>{(this.state.rate).toFixed(5)}</td>
                <td>{parseFloat(this.state.bankRate).toFixed(5)}</td>
                <td className='you-save'>
                  {
                    this.state.youSave > 0 ? `${this.state.currencyTo} ${(this.state.youSave).toFixed(2)}` : <></>
                  }
                </td>

              </tr>
              <tr>
                <td><strong>{t('fee')}</strong></td>
                <td>£0.00</td>
                <td>£21.00</td>
                <td className='you-save'>£21.00</td>
              </tr>
            </tbody>
          </Table> : <div></div>}

        <div className="text-right mt-3">

          <Button href={this.state.platformLink} className='btn-get-started txt-white' size="lg">
            {t('getstarted')}
          </Button>
        </div>
      </div >
    );
  }
}

export default withTranslation('common')(Quote);
