import React from "react";

import "./App.scss";
import Quote from "./components/container/Quote";

function App(props) {
  return (
    <div className="container">
      <div className="App row">
        <Quote lang={props.lang} />
      </div>
    </div>
  );
}

export default App;
